import React from 'react';
import { graphql } from 'gatsby';

import {
    layout,
    hero,
    navigationSection,
    navigation,
    listSection,
    list,
    card,
} from './what-we-do.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IOfferCard, IOfferCategory } from '../models/offer.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import OfferCategoryNavigationItem from '../components/atoms/offer-category-navigation-item';
import OfferCard from '../components/molecules/offer-card';

interface IWhatWeDoPageProps {
    readonly pageContext: {
        offerCategoryId?: number;
    };
    readonly data: {
        allOffer: IQueryAllResult<IOfferCard>;
        allOfferCategory: IQueryAllResult<IOfferCategory>;
    };
}

const WhatWeDoPage: React.FC<IWhatWeDoPageProps> = ({ data, pageContext }) => {
    const { offerCategoryId } = pageContext;
    const { t } = useT();
    const { allOffer, allOfferCategory } = data;
    const offerCards = getNodes(allOffer);
    const offerCategories = getNodes(allOfferCategory);

    return (
        <MainLayout className={layout} showFloatingCircles={true}>
            <Hero
                className={hero}
                titleProps={{ children: t('what.hero.title'), Tag: 'h1' }}
                color="yellow"
            />
            <Section
                className={navigationSection}
                theme="dark"
                circleDisplay="none"
                height="auto"
                contentDisplay="center"
            >
                <ul className={navigation}>
                    {offerCategories.map((offerCategory) => {
                        return (
                            <li key={offerCategory.offerCategoryId}>
                                <OfferCategoryNavigationItem offerCategory={offerCategory} />
                            </li>
                        );
                    })}
                </ul>
            </Section>
            {offerCategoryId && (
                <Section
                    className={listSection}
                    circleDisplay="none"
                    height="auto"
                    contentDisplay="center"
                    theme="light"
                >
                    <ul className={list}>
                        {offerCards.map((offerCard) => {
                            return (
                                <li key={`offer-item-${offerCard.offerId}`}>
                                    <OfferCard className={card} offerCard={offerCard} />
                                </li>
                            );
                        })}
                    </ul>
                </Section>
            )}
        </MainLayout>
    );
};

export const query = graphql`
    query WhatWeDoPage($locale: String!, $offerCategoryId: Int) {
        allOffer(
            filter: {
                locale: { eq: $locale }
                category: { offerCategoryId: { eq: $offerCategoryId } }
            }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...offerCardFields
                }
            }
        }
        allOfferCategory(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...offerCategoryFields
                }
            }
        }
    }
`;

export default WhatWeDoPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
